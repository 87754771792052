import Container from "../components/container";
import SEO from "../components/seo";
import React from "react";
import Layout from "../containers/layout";
import AboutCopy from "../components/about-copy";

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About Me" />
      <Container>
        <AboutCopy />
      </Container>
    </Layout>
  );
};

export default AboutPage;
