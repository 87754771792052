import React from "react";
import * as styles from "./about-copy.module.css";

const AboutCopy = () => {
  return (
    <div className={styles.root}>
      <h1>About Me</h1>
      <hr />
      <ul>
        <li>
          <a href="#living">Where do you live? Where are you from?</a>
        </li>
        <li>
          <a href="#pets">Do you have any pets?</a>
        </li>
        <li>
          <a href="#education">What is your education background?</a>
        </li>
        <li>
          <a href="#hobbies">Any hobbies outside of coding?</a>
        </li>
        <li>
          <a href="#tools">What tech tools do you use?</a>
        </li>
      </ul>
      <hr />
      <section id="living">
        <h2>Location</h2>
        <p>
          I&apos;m originally from Southern California, but I currently live in
          Central Texas.
        </p>
      </section>
      <section id="pets">
        <h2>Pets</h2>
        <p>
          I have a nameless cat and she is very cute; you can check out here
          instagram{" "}
          <a
            href="https://www.instagram.com/nameless_gato/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          . I also have a leopard gecko named Günter. They are both wonderful
          and cute little creatures that I love having around. Someday I hope to
          at least get some chickens, but a whole farm would be even better.
        </p>
      </section>
      <section id="education">
        <h2>Educational Background</h2>
        <p>
          I have a degree in Computer Science from Oregon State and a degree in
          Psychology from UC San Diego. I studied the latter first while I still
          thought I wanted to pursue medicine to become a psychiatrist. In my
          last year I discovered programming and the desire to pursue that
          bloomed from there. I&apos;m happy to be in a field where using my
          problem solving skills to build something and use my creativity.
        </p>
      </section>
      <section id="hobbies">
        <h2>Hobbies</h2>
        <p>
          I am an avid baker and home cook; I could spend far too long on the
          topic of food and drink. Aside from baking and coding, I do enjoy some
          video games, watercoloring, watching good things on different
          streaming services, reading, and exercise. I also like to do some
          gardening with indoor plants and some plants that do well in pots
          outdoors. Hopefully, in the future, I can have a bigger and more
          fruitful garden.
        </p>
      </section>
      <section id="tools">
        <h2>Tools I use</h2>
        <p>
          I use a prettty old Windows PC for my main use, but sometimes I use an
          ASUS Chromebook. Fun fact, I grew up using Apple products only, so
          only when I was out of college did I look into building my PC and
          start using windows. I had a macbook pro up until recently, I then
          installed Linux mint to try to save it, but that only lasted so long
          until I just decided to get a chromebook (I think the laptop hardware
          was just on it&apos;s last leg). So safe to say I am very comfortable
          with Windows, Mac OS, and Linux!
        </p>
        <p>
          I use visual studio code as my IDE with jetbrain mono font and Nord
          color theme (it is so soothing!). Sometimes I will use vim when I am
          doing stuff just in linux terminal and it needs to be a quick test
          file or something like that. Fun fact #2, I spent my first 2
          programming classes, in my CS degree, using only vim to produce my C++
          code. It was a fun and tedious experience, but at least I feel very
          comfortable with vim, for whatever that is worth.
        </p>
      </section>
    </div>
  );
};

export default AboutCopy;
